<template>
  <div :class="{ 'is-open': isOpen }">
    <SlideUpDown :active="isOpen" :duration="300">
      <slot/>
    </SlideUpDown>
    <div class="mo:text-center">
      <button class="btn-toggle" @click="toggle"></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
.btn-toggle {
  @apply rounded rounded-full bg-brand-gray w-8 h-8 mt-8;
  background-image: url('~@/assets/svg/icons/arrow-h-w.svg');
  background-position: 55% 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  transform: rotate(90deg);
  transition: all 0.2s ease;
}

.is-open .btn-toggle {
  background-position: 50% 50%;
  transform: rotate(270deg);
}
</style>
