<template>
  <div>
    <div class="mb-8 text-sm w-full flex space-x-4 items-center">
      <p class="font-light w-icon-checkmark">{{ labels.mileage }}</p>
      <v-select
        class="w-full"
        :options="prices.shortTerm"
        label="distance"
        :clearable="false"
        :searchable="false"
        v-model="selectedMileage"
        @input="$emit('select', selectedMileage)"
      />
    </div>
    <div>
      <template v-if="prices.hasOwnProperty('midTerm') || prices.hasOwnProperty('longTerm')">
         <div class="flex pt-2 border-t text-center">
           <div class="w-1/3 pb-6 pt-3 border-r">
              <mileage-price-item
                :price="getPrice('shortTerm')"
                :discountedPrice="getDiscountedPrice('shortTerm')"
                :labels="{ term: labels.shortTerm, month: labels.month }"
              />
          </div>
           <div v-if="prices.hasOwnProperty('midTerm')" class="w-1/3 pb-6 pt-3 border-r">
              <mileage-price-item
                :price="getPrice('midTerm')"
                :discountedPrice="getDiscountedPrice('midTerm')"
                :labels="{ term: labels.midTerm, month: labels.month }"
              />
           </div>
           <div v-if="prices.hasOwnProperty('longTerm')" class="w-1/3 pb-6 pt-3" >
             <mileage-price-item
               :price="getPrice('longTerm')"
               :discountedPrice="getDiscountedPrice('longTerm')"
               :labels="{ term: labels.longTerm, month: labels.month }"
             />
           </div>
         </div>
      </template>
      <template v-else>
        <div v-if="showDiscountBadge()" class="relative mt-10">
          <aside
            class="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-brand-a shadow-xl leading-tight text-white flex flex-col items-center justify-center absolute top-1/2 right-0 mo:mb-6"
            style="transform:rotate(3deg) translateY(-10%)"
          >
          <span class="text-2xl sm:text-3xl font-bold tracking-tight">
            -{{ percentageDiscount('shortTerm') }}%
          </span>
            <span class="px-3 text-2xs sm:text-xs font-light text-center">
            {{ labels.discountDuration }}
          </span>
          </aside>
        </div>
          <mileage-price-item
            :price="getPrice('shortTerm')"
            :discountedPrice="getDiscountedPrice('shortTerm')"
            :labels="{ term: labels.shortTerm, month: labels.month }"
            large
          />
          <aside class="text-xs mt-4" v-if="labels.discountInfo">
            <p class="font-bold" style="white-space: pre-line">{{ labels.discountInfo }}</p>
          </aside>
      </template>
    </div>
  </div>
</template>

<script>
import MileagePriceItem from '@/components/MileagePriceItem'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  props: {
    prices: {
      type: Object,
      required: true
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
    MileagePriceItem,
    vSelect
  },
  data() {
    return {
      options: [],
      selectedMileage: {
        distance: '',
        id: 0
      },
    }
  },
  mounted() {
    this.selectedMileage = this.prices.shortTerm[0]
    this.$emit('select', this.selectedMileage)
  },
  methods: {
    percentageDiscount(term) {
      return Math.round((1 - this.getDiscountedPrice(term) / this.getPrice(term)) * 100)
    },
    getPrice(term) {
      return this.prices[term][this.selectedMileage.id].price.toFixed(2)
    },
    getDiscountedPrice(term) {
      const discount = this.prices[term][this.selectedMileage.id].discount
      return (this.getPrice(term) - discount).toFixed(2)
    },
    showDiscountBadge() {
     return !this.prices.hasOwnProperty('midTerm') &&
       !this.prices.hasOwnProperty('longTerm') &&
       this.prices.shortTerm[0].discount > 0;
    }
  }
}
</script>
<style scoped>
>>> {
  --vs-dropdown-option--active-bg: black;
}
</style>
