import './polyfills'
import 'lazysizes'
import Vue from 'vue'
import store from './store'
import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion'
import SlideUpDown from 'vue-slide-up-down'
import Inquiry from 'hertz-inquiry'

import AnchorLink from './components/AnchorLink'
import BurgerButton from './components/BurgerButton'
import Expander from './components/Expander'
import LanguageSwitcher from './components/LanguageSwitcher'
import SwiperSlider from './components/SwiperSlider'
import Tooltip from './components/Tooltip'
import LinkGate from './components/LinkGate'
import pageLoadAnchor from '@/util/page-load-anchor'
import DeviceMixin from './mixins/device'
import Mileage from "./components/Mileage"

import './app.css'

Vue.config.productionTip = false

Vue.mixin(DeviceMixin)

Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('SlideUpDown', SlideUpDown)

Vue.component('AnchorLink', AnchorLink)
Vue.component('BurgerButton', BurgerButton)
Vue.component('Expander', Expander)
Vue.component('Inquiry', Inquiry)
Vue.component('LanguageSwitcher', LanguageSwitcher)
Vue.component('SwiperSlider', SwiperSlider)
Vue.component('Tooltip', Tooltip)
Vue.component('LinkGate', LinkGate)
Vue.component('Stations', () => import(/* webpackChunkName: "stations" */ './components/Stations'))
Vue.component('Mileage', Mileage)

new Vue({
  el: "#kia-root",
  store,
  delimiters: ["${", "}"]
})
