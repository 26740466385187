<template>
  <div>
    <p v-if="!large" class="mb-2 text-brand-a text-xs font-light">{{ labels.term }}</p>
    <div v-if="discountedPrice !== price" class="-mt-2" >
      <p
        class="font-toyota-display subpixel-antialiased text-gray-600 strikethru opacity-75 leading-none text-center"
        :class="{ 'text-md' : large, 'text-xs' : !large }"
      >
        CHF {{ price }}
      </p>
    </div>
    <p
      class="font-toyota-display font-bold subpixel-antialiased text-gray-600 leading-none"
      :class="{ 'text-2xl': large, 'text-md text-center': !large }"
    >
      CHF {{ discountedPrice }} <span class="text-2xs">/ {{ labels.month }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Object,
      required: true,
    },
    discountedPrice: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    large: {
      type: Boolean,
      default: false
    }
  },

}
</script>
