<template>
  <div>
    <div class="mb-8 mo:text-center">
      <mileage-prices :prices="prices" :labels="labels" @select="onSelectMileage"/>
    </div>
    <template v-if="preselect">
      <div class="sm:max-w-xs">
        <template v-if="available">
          <a class="button button--uppercase button--pill button--full" @click.prevent="select">
            {{ labels.bookNow }}
          </a>
        </template>
        <template v-else>
          <h3 class="italic">{{ labels.soldOut }}</h3>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="xl:flex">
        <div class="xl:w-1/2 xl:pr-1 mb-2 xl:mb-0">
          <slot />
        </div>
        <div class="xl:w-1/2 xl:pl-1">
          <a class="button button--uppercase button--pill button--full" @click.prevent="select">
            {{ labels.buttonRent }}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MileagePrices from "@/components/MileagePrices"
export default {
  props: {
    available: {
      type: Boolean,
      required: true,
    },
    preselect: {
      type: Boolean,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    prices: {
      type: Object,
      required: true
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
    MileagePrices
  },
  data() {
    return {
      selectedMileage: ''
    }
  },
  mounted() {
    if (this.preselect) {
      this.$store.commit('selectVehicle', this.vehicle)
    }
  },
  methods: {
    onSelectMileage(selectedMileage) {
      this.selectedMileage = selectedMileage
    },
    select() {
      this.vehicle.title = `${this.vehicle.model} (${this.selectedMileage.distance})`
      this.$store.commit('selectVehicle', this.vehicle)
      const $a = document.querySelector('a[name="inquiry"]')
      window.scrollTo({ top: $a.offsetTop, behavior: 'smooth' })
    }
  }
}
</script>
